import Watermark from '@/utils/watermark'
import { getManualDetail, getManualList, getManualMenuList } from "@/views/center/operatingManual/api";
export default {
  components: {},
  name: "",
  data() {
    return {
      waterMark: null,// 水印
      pageLoadFlag: false,
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页",
        },
        {
          path: "/noticeList",
          name: "noticeList",
          title: "操作手册",
        },
      ],
      keywords:'',// 关键字
      actionList: [],
      selectId:'19943101615', // 选中的指引id
      item: {},
      iframeSrc:'/operatingManualH5',
      fatherId:'',
      articleName: undefined,
      articleId: undefined,
      articleList: [],
      fetching:false,
      pageLoading:false,

    };
  },
  watch: {
    $route: function(to) {
      window.location.reload()
    }
  },

  mounted () {
    this.getMenu()
    this.getDetail(this.selectId?this.selectId:'19943101615')
    Watermark.set( this.$store.state.user.userInfo.account.account,this.$refs.watermarkDiv,this.$util.getNowDate())
  },

  beforeDestroy() {
    Watermark.set("")
  },
  methods:{
    // 获取操作手册菜单
     getMenu(){
       getManualMenuList({}).then(res=>{
        if (res.data.code == 0){
          let list  = this.getNewObj( res.data.data)
          this.actionList = list
          if( this.actionList.length>0){
            this.actionList.forEach(async(it)=>{
              it.childList = []
              it.expand = false
              if(it.id == 0){
                it.type='bdbk'
              }
              if(it.id == 1){
                it.type='xd'
              }
              if(it.id == 2){
                it.type='xs'
              }
              if(it.id == 3){
                it.type='kc'
              }
              if(it.id == 4){
                it.type='th'
              }
              if(it.id == 5){
                it.type='cw'
              }
              if(it.id == 6){
                it.type='gl'
              }
              if(it.id == 7){
                it.type='grzx'
              }
              if(it.id == 8){
                it.type='hxxt'
              }
              if(it.id == 9){
                it.type='qt'
              }



              const childArr = await this.getMenuChild(it.id)
              it.childList = childArr.data.data?childArr.data.data:[]
              this.$forceUpdate()
              if(it.childList.length > 0){
                it.childList.forEach(el=>{
                  el.readFlag = false
                  el.selected = false
                })
              }
              let  id = this.$route.query.type?this.$route.query.type:''
              if(id){
                if (it.type == id){
                  it.expand=true
                  this.selectId = it.childList && it.childList.length > 0 ? it.childList[0].id:''
                  // 如果没有的话跳转到欢迎使用 欢迎使用的id需要后提供
                  this.getDetail(this.selectId?this.selectId:'19943101615')
                }
                document.querySelector('#'+id).scrollIntoView(true)
              }
            })
          }
        }else {
          this.$message.warning(res.data.msg)
          return
        }
      })
    },
    // 获取自菜单
    getMenuChild(id){
      return getManualList({menuCode:id})
    },
    getNewObj(obj) {
      let newArr = [];
      for (let key in obj) {
        newArr.push({
          id: key,
          name: obj[key]
        });
      }
      return newArr;
    },
    articleIdInput(e) {
      let data = {
        title: e?e:'',
      };
      this.fetching = true
      getManualList(data).then((res) => {
        this.fetching = false
        if (res.data.code == 0) {
          this.articleList = res.data.data;
        } else{
          this.articleList = []
        }
      });
    },
    // 搜索选中文章
    articleIdChange(e) {
       // 如果清空选择 默认到欢迎使用上  此处需要后端提供欢迎使用的id
      this.selectId = e?e:''
      this.articleId = e?e:''
      if (e){
        this.pageLoading=true
        this.getDetail(e)
      } else{
        this.pageLoading=true
        this.getDetail(19943101615)
        this.selectId = 19943101615
      }


    },
    // 获取详情
    getDetail(id){
      getManualDetail({manualId:id}).then(res=>{
        this.pageLoading=false
        if(res.data.code == 0){
          this.item = res.data.data
        }else{
          this.item = {}
        }
      })
    },
    // 关键字查询接口 获取数据
    onSearchKeywords() {},
    expandClik(item) {
      item.expand = !item.expand
      this.$forceUpdate()
    },
    changeSelected(item){
      item.read = true
      this.selectId = item.id
      this.articleId = undefined
      this.pageLoading=true
      this.getDetail(item.id)
    }
  }
};