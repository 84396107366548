let watermark = {};
let setWatermark = (text,sourceBody,text1) => {
  let id = 'watermark_fjq_' + parseInt(Math.random() * 100000);
  if (document.getElementById(id) !== null) {
    document.body.removeChild(document.getElementById(id));
  }
  //水印图片
  let can = document.createElement('canvas');
  can.width = 210;// 单个水印大小
  can.height = 220;// 单个水印大小
  let cans = can.getContext('2d');
  cans.rotate(-20 * Math.PI / 180);
  cans.font = '14px 方正楷体';
  cans.fontWeight = '300';
  cans.fillStyle = '#e0e0e0';//水印颜色
  cans.textAlign = 'left';
  // cans.textBaseline = 'Middle';
  cans.font="方正楷体";//水印字体
  let can1 = document.createElement('canvas');
  can1.width = 210;// 单个水印大小
  can1.height = 220;// 单个水印大小
  let cans1 = can.getContext('2d');
  cans1.rotate(-20 * Math.PI / 180);
  cans1.font = '14px 方正楷体';
  cans1.fontWeight = '300';
  cans1.fillStyle = '#e0e0e0';//水印颜色
  cans1.textAlign = 'left';
  // cans1.textBaseline = 'Middle';
  cans1.font="方正楷体";//水印字体
  cans.fillText(text, can.width / 20, can.height/2);
  cans.fillText(text1, can1.width / 20, can1.height/1.7);
  //设置插入div样式
  let water_div = document.createElement('div');
  water_div.id = id;
  water_div.style.pointerEvents = 'none';
  water_div.style.overflow = 'hidden';
  water_div.style.background = 'url(' + can.toDataURL('image/png') + ') left top repeat';
  if (sourceBody) {
    sourceBody.style.position = 'relative';
    water_div.style.width = '100%';
    water_div.style.height = '100%';
    water_div.style.position = 'absolute';
    water_div.style.top = '0';
    water_div.style.left = '0';
    sourceBody.appendChild(water_div);
  } else {
    water_div.style.top = '3px';
    water_div.style.left = '0px';
    water_div.style.position = 'fixed';
    water_div.style.zIndex = '9999';
    water_div.style.width = document.documentElement.clientWidth + 'px';
    water_div.style.height = document.documentElement.clientHeight + 'px';
    document.body.removeChild(water_div);
  }
  return id;
}

/**
 *  该方法只允许调用一次
 *  @param:
 *  @text == 水印内容
 *  @sourceBody == 水印添加在哪里，不传就是body
 * */
watermark.set = (text, sourceBody,text1) => {
  setTimeout(() => {
    setWatermark(text, sourceBody,text1);
  }, 1000);//延迟加载
}

export default watermark;