
import http from "@/utils/request";
// 查询所有菜单
export function getManualMenuList(data) {
  return http({
    method: "get",
    url: "/billboard/getManualMenuList.nd",
    data
  });
}
// 查询所有文章
export function getManualList(data) {
  return http({
    method: "post",
    url: "/billboard/getManualList.nd",
    data
  });
}
//文章详情
export function getManualDetail(data) {
  return http({
    method: "post",
    url: "/billboard/getManualDetail.nd",
    data
  });
}
export function getManualDetailH5(data) {
  return http({
    method: "post",
    url: "/billboard/getManualDetail.api",
    data
  });
}